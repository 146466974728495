
import { defineComponent, ref } from "@vue/runtime-core";
import VInput from "@/components/form/VInput.vue";
import VSubmit from "@/components/form/VSubmit.vue";
import { ContactUsRequest } from "@/types";

export default defineComponent({
  name: "ContactUs",
  components: {
    VInput,
    VSubmit,
  },
  setup() {
    return {
      name: ref(""),
      emailAddress: ref(""),
      message: ref(""),
    };
  },
  methods: {
    error(key: string) {
      return this.$store.getters.error(key);
    },
    loading(key: string) {
      return this.$store.getters.loading(key);
    },
    async submit(): Promise<void> {
      await this.$recaptchaLoaded();

      const data = new ContactUsRequest({
        name: this.name,
        emailAddress: this.emailAddress,
        message: this.message,
        recaptchaChallengeToken: await this.$recaptcha("login"),
      });

      if (!data.validate(this.$store)) {
        return;
      }

      await this.$store.dispatch("contactUs", data);
      this.name = "";
      this.emailAddress = "";
      this.message = "";
    },
  },
});
